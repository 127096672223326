import React, { useState } from 'react';
import { Monitor, Ruler, Eye, Maximize2, Calculator, MonitorCheck } from 'lucide-react';

const FOVCalculator = () => {
  const [inputs, setInputs] = useState({
    monitorSetup: 'single',
    screenWidth: '',
    distanceToScreen: '',
    bezelWidth: '0'
  });

  const [results, setResults] = useState({
    numScreens: '',
    monitorWidth: '',
    visibleWidth: '',
    viewingDistance: '',
    fieldOfView: '',
    angleTriple: ''
  });

  const calculateFOV = () => {
    const width = parseFloat(inputs.screenWidth); // em polegadas
    const distance = parseFloat(inputs.distanceToScreen) / 2.54; // converte cm para polegadas
    const bezel = parseFloat(inputs.bezelWidth) / 25.4; // converte mm para polegadas
    
    let numScreens = "1";
    let visibleWidth = width;
    let angleTriple = '';
    
    if (inputs.monitorSetup === 'triple') {
      visibleWidth = width - (bezel * 2);
      numScreens = "3";
      
      // Primeiro calcula o FOV como se fosse single screen
      const singleScreenFov = 2 * Math.atan((width/2) / distance) * (180/Math.PI);
      // FOV total é 3x o FOV de uma única tela
      const fov = singleScreenFov * 3;
      
      // Cálculo do ângulo entre as telas para configuração
      const angleRad = Math.atan((width + bezel) / distance);
      angleTriple = Math.round(angleRad * (180/Math.PI));
      
      setResults({
        numScreens: numScreens,
        monitorWidth: `${width}"`,
        visibleWidth: `${Math.round(visibleWidth * 100) / 100}"`,
        viewingDistance: `${Math.round(inputs.distanceToScreen)}cm`,
        fieldOfView: `${Math.round(fov)}°`,
        angleTriple: `${angleTriple}°`
      });
    } else {
      // Cálculo para tela única
      const fov = 2 * Math.atan((width/2) / distance) * (180/Math.PI);
      
      setResults({
        numScreens: numScreens,
        monitorWidth: `${width}"`,
        visibleWidth: `${width}"`,
        viewingDistance: `${Math.round(inputs.distanceToScreen)}cm`,
        fieldOfView: `${Math.round(fov)}°`,
        angleTriple: ''
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="bg-gray-800 rounded-lg p-6 mt-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-white">Campo de Visão</h2>
        <div className="flex items-center text-gray-400">
          <MonitorCheck className="h-5 w-5 mr-2" />
          <span>FOV Settings</span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-6">
          <div className="bg-gray-700/50 p-4 rounded-lg">
            <div className="flex items-center mb-4">
              <Monitor className="h-5 w-5 text-blue-500 mr-2" />
              <span className="text-white font-medium">Configuração do Monitor</span>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => handleInputChange({ target: { name: 'monitorSetup', value: 'single' }})}
                className={`p-3 rounded-lg flex items-center justify-center ${
                  inputs.monitorSetup === 'single' 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-gray-700 text-gray-300'
                }`}
              >
                <Monitor className="h-4 w-4 mr-2" />
                Single (1)
              </button>
              <button
                onClick={() => handleInputChange({ target: { name: 'monitorSetup', value: 'triple' }})}
                className={`p-3 rounded-lg flex items-center justify-center ${
                  inputs.monitorSetup === 'triple' 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-gray-700 text-gray-300'
                }`}
              >
                <Maximize2 className="h-4 w-4 mr-2" />
                Triple (3)
              </button>
            </div>
          </div>

          <div className="space-y-4">
            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <Ruler className="h-5 w-5 text-blue-500 mr-2" />
                <label className="text-white font-medium">Largura da Tela (polegadas)</label>
              </div>
              <input
                type="number"
                name="screenWidth"
                value={inputs.screenWidth}
                onChange={handleInputChange}
                className="w-full bg-gray-700 text-white rounded-lg p-2 border border-gray-600"
                placeholder="27"
              />
            </div>

            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <Eye className="h-5 w-5 text-blue-500 mr-2" />
                <label className="text-white font-medium">Distância dos Olhos à Tela (cm)</label>
              </div>
              <input
                type="number"
                name="distanceToScreen"
                value={inputs.distanceToScreen}
                onChange={handleInputChange}
                className="w-full bg-gray-700 text-white rounded-lg p-2 border border-gray-600"
                placeholder="50"
              />
            </div>

            {inputs.monitorSetup === 'triple' && (
              <div className="bg-gray-700/50 p-4 rounded-lg">
                <div className="flex items-center mb-4">
                  <Ruler className="h-5 w-5 text-blue-500 mr-2" />
                  <label className="text-white font-medium">Largura da Borda (mm)</label>
                </div>
                <input
                  type="number"
                  name="bezelWidth"
                  value={inputs.bezelWidth}
                  onChange={handleInputChange}
                  className="w-full bg-gray-700 text-white rounded-lg p-2 border border-gray-600"
                  placeholder="1"
                />
              </div>
            )}

            <button
              onClick={calculateFOV}
              className="w-full bg-blue-600 text-white rounded-lg py-3 px-4 hover:bg-blue-700 transition-colors flex items-center justify-center"
            >
              <Calculator className="h-5 w-5 mr-2" />
              Calcular FOV
            </button>
          </div>
        </div>

        <div className="bg-gray-700/50 rounded-lg p-6">
          <h3 className="text-xl font-bold text-white mb-6 flex items-center">
            <MonitorCheck className="h-5 w-5 text-blue-500 mr-2" />
            Configurações iRacing
          </h3>
          
          <div className="space-y-4">
            <div className="flex justify-between items-center py-2 border-b border-gray-600">
              <span className="text-gray-300">Número de Telas</span>
              <span className="text-white font-bold">{results.numScreens || '-'}</span>
            </div>
            
            <div className="flex justify-between items-center py-2 border-b border-gray-600">
              <span className="text-gray-300">Largura com Borda</span>
              <span className="text-white font-bold">{results.monitorWidth || '-'}</span>
            </div>
            
            <div className="flex justify-between items-center py-2 border-b border-gray-600">
              <span className="text-gray-300">Largura sem Borda</span>
              <span className="text-white font-bold">{results.visibleWidth || '-'}</span>
            </div>
            
            <div className="flex justify-between items-center py-2 border-b border-gray-600">
              <span className="text-gray-300">Distância de Visualização</span>
              <span className="text-white font-bold">{results.viewingDistance || '-'}</span>
            </div>

            {inputs.monitorSetup === 'triple' && results.angleTriple && (
              <div className="flex justify-between items-center py-2 border-b border-gray-600">
                <span className="text-gray-300">Ângulo entre as Telas</span>
                <span className="text-white font-bold">{results.angleTriple || '-'}</span>
              </div>
            )}
            
            <div className="flex justify-between items-center py-2">
              <span className="text-gray-300">Campo de Visão</span>
              <span className="text-white font-bold text-xl">{results.fieldOfView || '-'}</span>
            </div>
          </div>

          {results.fieldOfView && (
            <div className="mt-6 pt-4 border-t border-gray-600">
              <h4 className="text-white font-medium mb-2 flex items-center">
                <Calculator className="h-4 w-4 text-blue-500 mr-2" />
                Como aplicar
              </h4>
              <ol className="text-sm text-gray-300 space-y-1 list-decimal list-inside">
                <li>Abra o iRacing</li>
                <li>Acesse Options {'>'} Graphics</li>
                <li>Configure o FOV com os valores acima</li>
                {inputs.monitorSetup === 'triple' && (
                  <li>Configure o ângulo entre as telas em {results.angleTriple}</li>
                )}
              </ol>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FOVCalculator;