// src/components/SEO/pageSEO.js
export const PAGE_SEO = {
    home: {
      title: 'iSetup - Gerenciador de Configurações para iRacing',
      description: 'Otimize seu desempenho no iRacing com o iSetup. Gerencie setups, analise telemetria e melhore seus tempos de volta.',
      keywords: 'iracing, setup, telemetria, simulação de corrida, otimização de performance',
    },
    telemetry: {
      title: 'Análise de Telemetria | iSetup',
      description: 'Analise dados detalhados de telemetria do iRacing. Visualize métricas de performance, compare voltas e identifique áreas de melhoria.',
      keywords: 'telemetria iracing, análise de dados, performance racing, simulação',
    },
    setup: {
      title: 'Configurações de Setup | iSetup',
      description: 'Gerencie e otimize seus setups do iRacing. Ferramentas avançadas para ajuste e compartilhamento de configurações.',
      keywords: 'setup iracing, configuração carro, ajuste racing, simulador',
    },
    admin: {
      title: 'Painel Administrativo | iSetup',
      description: 'Gerencie usuários, configurações e conteúdo do iSetup.',
      keywords: 'admin iracing, gestão setup, administração sistema',
      noindex: true, // Páginas admin não devem ser indexadas
    },
    login: {
      title: 'Login | iSetup',
      description: 'Acesse sua conta no iSetup e gerencie suas configurações do iRacing.',
      keywords: 'login iracing, acesso setup, conta usuário',
      noindex: true, // Páginas de autenticação não devem ser indexadas
    },
    register: {
      title: 'Criar Conta | iSetup',
      description: 'Crie sua conta no iSetup e comece a otimizar seu desempenho no iRacing.',
      keywords: 'registro iracing, criar conta, cadastro setup',
      noindex: true, // Páginas de autenticação não devem ser indexadas
    },
  };