import React from 'react';

const SetupRow = ({ label, value }) => (
  <div className="flex justify-between py-0.5">
    <span className="text-[#f8f0f8] text-sm font-mono brightness-75">{label}:</span>
    <span className="text-[#4488FF] text-sm font-mono">{value}</span>
  </div>
);

const SetupSection = ({ title, children }) => (
  <div className="bg-opacity-20 backdrop-blur-sm bg-gray-900 rounded-lg p-4">
    <h3 className="text-[#f8f0f8] mb-3 font-bold tracking-wide text-sm">{title}</h3>
    <div className="space-y-1">
      {children}
    </div>
  </div>
);

const findValue = (obj, paths) => {
  for (const path of paths) {
    const value = path.split('.').reduce((acc, part) => acc?.[part], obj);
    if (value !== undefined) return value;
  }
  return null;
};

const DamperSection = ({ title, damper }) => {
  const hasAdvancedSettings = damper?.LowSpeedCompressionDamping !== undefined;

  if (hasAdvancedSettings) {
    return (
      <SetupSection title={title}>
        <SetupRow 
          label="Low Speed Compression" 
          value={`${damper?.LowSpeedCompressionDamping || 0}`} 
        />
        <SetupRow 
          label="High Speed Compression" 
          value={`${damper?.HighSpeedCompressionDamping || 0}`} 
        />
        <SetupRow 
          label="Low Speed Rebound" 
          value={`${damper?.LowSpeedReboundDamping || 0}`} 
        />
        <SetupRow 
          label="High Speed Rebound" 
          value={`${damper?.HighSpeedReboundDamping || 0}`} 
        />
      </SetupSection>
    );
  }

  return (
    <SetupSection title={title}>
      <SetupRow 
        label="Compression" 
        value={`${damper?.CompressionDamping || 0}`} 
      />
      <SetupRow 
        label="Rebound" 
        value={`${damper?.ReboundDamping || 0}`} 
      />
    </SetupSection>
  );
};

const SetupTab = ({ setupData }) => {
  if (!setupData) return null;
  const { TiresAero, Chassis, GearsDifferential, Dampers } = setupData;

  // Helper para encontrar valores em diferentes caminhos possíveis
  const getFrontValue = (paths) => findValue(Chassis, [
    'Front',
    'FrontBrakes',
    'FrontBrakesLights'
  ].map(prefix => paths.map(p => `${prefix}.${p}`)).flat());

  const getInCarValue = (paths) => findValue(Chassis, [
    'InCarDials',
    'InCar',
    'InCarAdjustments'
  ].map(prefix => paths.map(p => `${prefix}.${p}`)).flat());

  return (
    <div className="bg-opacity-10 backdrop-blur-sm p-8 space-y-8">
      {/* TIRES & AERO */}
      <div>
        <h2 className="text-[#f8f0f8] font-bold text-lg mb-4">TIRES & AERO</h2>
        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-6">
            <SetupSection title="TIRE TYPE">
              <SetupRow label="Type" value={TiresAero?.TireType?.TireType || 'Dry'} />
            </SetupSection>

            <SetupSection title="LEFT FRONT">
              <SetupRow label="Cold Pressure" value={`${TiresAero?.LeftFront?.StartingPressure || 0} kPa`} />
              <SetupRow label="Last Hot Pressure" value={`${TiresAero?.LeftFront?.LastHotPressure || 0} kPa`} />
              <SetupRow label="Last Temps OMI" value={TiresAero?.LeftFront?.LastTempsOMI || ''} />
              <SetupRow label="Tread Remaining" value={TiresAero?.LeftFront?.TreadRemaining || ''} />
            </SetupSection>

            <SetupSection title="LEFT REAR">
              <SetupRow label="Cold Pressure" value={`${TiresAero?.LeftRear?.StartingPressure || 0} kPa`} />
              <SetupRow label="Last Hot Pressure" value={`${TiresAero?.LeftRear?.LastHotPressure || 0} kPa`} />
              <SetupRow label="Last Temps OMI" value={TiresAero?.LeftRear?.LastTempsOMI || ''} />
              <SetupRow label="Tread Remaining" value={TiresAero?.LeftRear?.TreadRemaining || ''} />
            </SetupSection>
          </div>

          <div className="space-y-6">
            <SetupSection title="AERO BALANCE">
              <SetupRow label="Front RH @ Speed" value={`${TiresAero?.AeroBalanceCalc?.FrontRhAtSpeed || 0} mm`} />
              <SetupRow label="Rear RH @ Speed" value={`${TiresAero?.AeroBalanceCalc?.RearRhAtSpeed || 0} mm`} />
              <SetupRow 
                label="Wing Angle" 
                value={TiresAero?.AeroBalanceCalc?.WingSetting || 
                       TiresAero?.AeroBalanceCalc?.RearWingAngle || 
                       '0 degrees'} 
              />
              <SetupRow label="Front Downforce" value={`${TiresAero?.AeroBalanceCalc?.FrontDownforce || 0}%`} />
            </SetupSection>

            <SetupSection title="RIGHT FRONT">
              <SetupRow label="Cold Pressure" value={`${TiresAero?.RightFront?.StartingPressure || 0} kPa`} />
              <SetupRow label="Last Hot Pressure" value={`${TiresAero?.RightFront?.LastHotPressure || 0} kPa`} />
              <SetupRow label="Last Temps IMO" value={TiresAero?.RightFront?.LastTempsIMO || ''} />
              <SetupRow label="Tread Remaining" value={TiresAero?.RightFront?.TreadRemaining || ''} />
            </SetupSection>

            <SetupSection title="RIGHT REAR">
              <SetupRow label="Cold Pressure" value={`${TiresAero?.RightRear?.StartingPressure || 0} kPa`} />
              <SetupRow label="Last Hot Pressure" value={`${TiresAero?.RightRear?.LastHotPressure || 0} kPa`} />
              <SetupRow label="Last Temps IMO" value={TiresAero?.RightRear?.LastTempsIMO || ''} />
              <SetupRow label="Tread Remaining" value={TiresAero?.RightRear?.TreadRemaining || ''} />
            </SetupSection>
          </div>
        </div>
      </div>

      {/* CHASSIS */}
      <div>
        <h2 className="text-[#f8f0f8] font-bold text-lg mb-4">CHASSIS</h2>
        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-6">
            <SetupSection title="GENERAL">
              <SetupRow 
                label="Front Toe In" 
                value={`${getFrontValue(['ToeIn', 'TotalToeIn']) || 0} mm`} 
              />
              <SetupRow 
                label="Front Master Cyl" 
                value={`${getFrontValue(['FrontMasterCyl']) || 0} mm`} 
              />
              <SetupRow 
                label="Rear Master Cyl" 
                value={`${getFrontValue(['RearMasterCyl']) || 0} mm`} 
              />
              <SetupRow 
                label="ARB Blades" 
                value={getFrontValue(['ArbBlades', 'FarbBlades']) || ''}
              />
              <SetupRow 
                label="Brake Pads" 
                value={getFrontValue(['BrakePads']) || ''} 
              />
            </SetupSection>

            <SetupSection title="LEFT FRONT">
              <SetupRow label="Corner Weight" value={`${Chassis?.LeftFront?.CornerWeight || 0} N`} />
              <SetupRow label="Ride Height" value={`${Chassis?.LeftFront?.RideHeight || 0} mm`} />
              <SetupRow 
                label="Bump Gap" 
                value={`${Chassis?.LeftFront?.BumpRubberGap || 0} mm`} 
              />
              <SetupRow label="Spring Rate" value={`${Chassis?.LeftFront?.SpringRate || 0} N/mm`} />
              <SetupRow label="Camber" value={`${Chassis?.LeftFront?.Camber || 0}°`} />
            </SetupSection>

            <SetupSection title="LEFT REAR">
              <SetupRow label="Corner Weight" value={`${Chassis?.LeftRear?.CornerWeight || 0} N`} />
              <SetupRow label="Ride Height" value={`${Chassis?.LeftRear?.RideHeight || 0} mm`} />
              <SetupRow 
                label="Bump Gap" 
                value={`${Chassis?.LeftRear?.BumpRubberGap || 0} mm`} 
              />
              <SetupRow label="Spring Rate" value={`${Chassis?.LeftRear?.SpringRate || 0} N/mm`} />
              <SetupRow label="Camber" value={`${Chassis?.LeftRear?.Camber || 0}°`} />
              <SetupRow label="Toe In" value={`${Chassis?.LeftRear?.ToeIn || 0} mm`} />
            </SetupSection>
          </div>

          <div className="space-y-6">
            <SetupSection title="ELECTRONICS">
              <SetupRow 
                label="Display Mode" 
                value={getInCarValue(['DisplayPage', 'DashDisplayPage']) || ''} 
              />
              <SetupRow 
                label="Brake Bias" 
                value={`${getInCarValue(['BrakePressureBias']) || 0}%`} 
              />
              <SetupRow 
                label="Traction Control" 
                value={getInCarValue(['TractionControlSetting', 'TcSetting']) || ''} 
              />
              <SetupRow 
                label="ABS Setting" 
                value={getInCarValue(['AbsSetting']) || ''} 
              />
              <SetupRow 
                label="Throttle Map" 
                value={getInCarValue(['ThrottleShapeSetting', 'ThrottleShape']) || ''} 
              />
              <SetupRow 
                label="LED Strips" 
                value={findValue(setupData, [
                  'Chassis.InCarDials.NightLedStrips',
                  'Chassis.Front.NightLedStrips',
                  'Chassis.FrontBrakesLights.NightLedStripColor'
                ]) || 'OFF'} 
              />
            </SetupSection>

            <SetupSection title="RIGHT FRONT">
              <SetupRow label="Corner Weight" value={`${Chassis?.RightFront?.CornerWeight || 0} N`} />
              <SetupRow label="Ride Height" value={`${Chassis?.RightFront?.RideHeight || 0} mm`} />
              <SetupRow 
                label="Bump Gap" 
                value={`${Chassis?.RightFront?.BumpRubberGap || 0} mm`} 
              />
              <SetupRow label="Spring Rate" value={`${Chassis?.RightFront?.SpringRate || 0} N/mm`} />
              <SetupRow label="Camber" value={`${Chassis?.RightFront?.Camber || 0}°`} />
            </SetupSection>

            <SetupSection title="RIGHT REAR">
              <SetupRow label="Corner Weight" value={`${Chassis?.RightRear?.CornerWeight || 0} N`} />
              <SetupRow label="Ride Height" value={`${Chassis?.RightRear?.RideHeight || 0} mm`} />
              <SetupRow 
                label="Bump Gap" 
                value={`${Chassis?.RightRear?.BumpRubberGap || 0} mm`} 
              />
              <SetupRow label="Spring Rate" value={`${Chassis?.RightRear?.SpringRate || 0} N/mm`} />
              <SetupRow label="Camber" value={`${Chassis?.RightRear?.Camber || 0}°`} />
              <SetupRow label="Toe In" value={`${Chassis?.RightRear?.ToeIn || 0} mm`} />
            </SetupSection>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* DIFFERENTIAL */}
        <div>
          <h2 className="text-[#f8f0f8] font-bold text-lg mb-4">DIFFERENTIAL</h2>
          <SetupSection title="SETTINGS">
            <SetupRow 
              label="Gear Stack" 
              value={GearsDifferential?.GearStack || GearsDifferential?.SixthGear || ''} 
            />
            <SetupRow 
              label="Friction Faces" 
              value={GearsDifferential?.FrictionFaces || 0} 
            />
            <SetupRow 
              label="Preload" 
              value={`${GearsDifferential?.DiffPreload || 0} Nm`} 
            />
          </SetupSection>
        </div>

        {/* DAMPERS */}
        <div>
          <h2 className="text-[#f8f0f8] font-bold text-lg mb-4">DAMPERS</h2>
          <div className="space-y-6">
            <DamperSection title="FRONT" damper={Dampers?.FrontDampers} />
            <DamperSection title="REAR" damper={Dampers?.RearDampers} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupTab;