import React, { useState } from 'react';
import CarIssueCard from './CarIssueCard';
import CornerPhaseCard from './CornerPhaseCard';
import AdjustmentsList from './AdjustmentsList';
import DownforceCalculator from './DownforceCalculator';
import TracksDownforceList from './TracksDownforceList';
import { RefreshCcw, Settings } from 'lucide-react';
import FOVCalculator from './FOVCalculator';

const SetupFlow = () => {
  const [currentSection, setCurrentSection] = useState('initial-diagnosis');
  const [currentIssue, setCurrentIssue] = useState(null);
  const [currentPhase, setCurrentPhase] = useState(null);

  const handleIssueSelect = (issue) => {
    setCurrentIssue(issue);
    setCurrentSection(issue === 'bumps' ? 'adjustments' : 'corner-phase');
  };

  const handlePhaseSelect = (phase) => {
    setCurrentPhase(phase);
    setCurrentSection('adjustments');
  };

  const handleReset = () => {
    setCurrentIssue(null);
    setCurrentPhase(null);
    setCurrentSection('initial-diagnosis');
  };

  return (
    <div id="setup-flow" className="space-y-8">
      {currentSection === 'initial-diagnosis' && (
        <div className="space-y-8">
          <h2 className="text-2xl font-bold text-white">Diagnóstico do Comportamento</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <CarIssueCard type="oversteer" onClick={() => handleIssueSelect('oversteer')} />
            <CarIssueCard type="understeer" onClick={() => handleIssueSelect('understeer')} />
            <CarIssueCard type="bumps" onClick={() => handleIssueSelect('bumps')} />
          </div>
          <div className="space-y-6">
            <div className="pt-8">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-white">Ajustes de Downforce</h2>
                <div className="flex items-center text-gray-400">
                  <Settings className="h-5 w-5 mr-2" />
                  <span>Configurações Aerodinâmicas</span>
                </div>
              </div>
              <DownforceCalculator />
              <TracksDownforceList />
            </div>
          </div>
        </div>
      )}

      {currentSection === 'corner-phase' && (
        <div className="space-y-8">
          <h2 className="text-2xl font-bold text-white">Fase da Curva</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <CornerPhaseCard phase="entry" onClick={() => handlePhaseSelect('entry')} />
            <CornerPhaseCard phase="mid" onClick={() => handlePhaseSelect('mid')} />
            <CornerPhaseCard phase="exit" onClick={() => handlePhaseSelect('exit')} />
          </div>
        </div>
      )}

      {currentSection === 'adjustments' && (
        <div className="space-y-8">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-white">Ajustes Recomendados</h2>
            <button
              onClick={handleReset}
              className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <RefreshCcw className="h-5 w-5" />
              <span>Recomeçar</span>
            </button>
          </div>
          <AdjustmentsList issue={currentIssue} phase={currentPhase} />
        </div>
      )}
    </div>
  );
};

export default SetupFlow;