// src/components/ui/alert.jsx
import React from 'react';

export const Alert = ({ children, variant = 'default', className = '', ...props }) => {
  const variants = {
    default: 'bg-gray-800 border-gray-700',
    destructive: 'bg-red-900/50 border-red-700 text-red-100'
  };

  return (
    <div
      className={`p-4 rounded-lg border ${variants[variant]} ${className}`}
      role="alert"
      {...props}
    >
      {children}
    </div>
  );
};

export const AlertTitle = ({ children, className = '', ...props }) => (
  <h5 className={`font-medium mb-1 flex items-center gap-2 ${className}`} {...props}>
    {children}
  </h5>
);

export const AlertDescription = ({ children, className = '', ...props }) => (
  <div className={`text-sm text-gray-400 ${className}`} {...props}>
    {children}
  </div>
);