// src/components/ui/badge.jsx
import React from "react";

export const Badge = React.forwardRef(({ 
  children, 
  variant = "default", 
  className = '', 
  ...props 
}, ref) => {
  const baseStyles = "inline-flex items-center px-2.5 py-0.5 text-xs font-semibold rounded-full";
  
  const variants = {
    default: "bg-blue-500 text-white",
    secondary: "bg-gray-700 text-gray-200",
    destructive: "bg-red-500/80 text-white",
    outline: "border border-gray-700 text-gray-200 hover:bg-gray-700/50"
  };

  const styles = `${baseStyles} ${variants[variant]} ${className}`;

  return (
    <div 
      ref={ref}
      className={styles}
      {...props}
    >
      {children}
    </div>
  );
});

Badge.displayName = "Badge";