import React, { useMemo } from 'react';

const LAP_COLORS = {
  current: '#00C8FF',
  compare1: '#FF00DD',
  compare2: '#00FF66',
  compare3: '#FFCC00'
};

const TrackMap = ({
  currentLapData = [], 
  comparisonData = [], 
  activePointIndex = null,
  sessionInfo = null,
  trackOverlay = null,
}) => {
  
  // Encontra o SVG da pista
  const trackPaths = useMemo(() => {
    if (!sessionInfo?.WeekendInfo?.TrackID?.value) return null;
    
    let track = Object.values(trackOverlay.tracks).find(
      t => t.trackId === sessionInfo.WeekendInfo.TrackID.value
    );

    if (!track && sessionInfo.WeekendInfo.TrackName) {
      const normalizedName = sessionInfo.WeekendInfo.TrackName
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/[^a-z0-9_]/g, '');
      
      track = trackOverlay.tracks[normalizedName];
    }

    if (track?.paths?.[0]) {
      const pathElement = document.createElementNS("http://www.w3.org/2000/svg", "path");
      pathElement.setAttribute("d", track.paths[0]);
      const bbox = pathElement.getBBox();
      
      const scale = Math.min(420 / bbox.width, 324 / bbox.height) * 0.9;
      const translateX = (420 - bbox.width * scale) / 2 - bbox.x * scale;
      const translateY = (324 - bbox.height * scale) / 2 - bbox.y * scale;
      
      return {
        mainPath: track.paths[0],
        pitPath: track.paths[1],
        transform: `translate(${translateX}, ${translateY}) scale(${scale})`
      };
    }

    return null;
  }, [sessionInfo, trackOverlay]);

  // Calcula posição no traçado
  const getTrackPosition = (distance) => {
    if (!trackPaths?.mainPath || typeof distance !== 'number') {
      return null;
    }
    
    try {
      const pathElement = document.createElementNS("http://www.w3.org/2000/svg", "path");
      pathElement.setAttribute("d", trackPaths.mainPath);
      
      const pathLength = pathElement.getTotalLength();
      
      // Calcula a posição no traçado (0 a 1)
      const trackLength = sessionInfo.WeekendInfo.TrackLength * 1000; // Converte para metros
      let pct = (distance / trackLength);
      
      // Garante que o valor está entre 0 e 1
      pct = ((pct % 1) + 1) % 1;
      
      // Obtém o ponto no path
      const point = pathElement.getPointAtLength(pct * pathLength);
      
      return point;
    } catch (err) {
      console.error('Track position error:', err);
      return null;
    }
  };

  // Renderiza posição atual
  const renderCurrentPosition = () => {
    const currentPoint = currentLapData[activePointIndex];
    if (!currentPoint) return null;

    const pos = getTrackPosition(currentPoint.distance);
    if (!pos) return null;

    return (
      <g transform={trackPaths.transform}>
        {/* Círculo de fundo/glow */}
        <circle
          cx={pos.x}
          cy={pos.y}
          r="8"
          fill={LAP_COLORS.current}
          opacity="0.3"
        />
        {/* Marcador principal */}
        <circle
          cx={pos.x}
          cy={pos.y}
          r="4"
          fill={LAP_COLORS.current}
        />
      </g>
    );
  };

  // Renderiza pontos de comparação
  const renderComparisonPoints = () => {
    if (!activePointIndex) return null;

    return comparisonData.map((comparison, index) => {
      if (!comparison?.data?.[activePointIndex]) return null;

      const pos = getTrackPosition(comparison.data[activePointIndex].distance);
      if (!pos) return null;

      return (
        <g key={`compare-${index}`} transform={trackPaths.transform}>
          {/* Círculo de fundo/glow */}
          <circle
            cx={pos.x}
            cy={pos.y}
            r="6"
            fill={LAP_COLORS[`compare${index + 1}`]}
            opacity="0.34"
          />
          {/* Marcador principal */}
          <circle
            cx={pos.x}
            cy={pos.y}
            r="3"
            fill={LAP_COLORS[`compare${index + 1}`]}
          />
        </g>
      );
    });
  };

  if (!trackPaths) {
    return (
      <div className="p-4 text-white">
        <p>Track not found</p>
        <div className="text-xs opacity-50 mt-2 space-y-1">
          <p>ID: {sessionInfo?.WeekendInfo?.TrackID?.value}</p>
          <p>Name: {sessionInfo?.WeekendInfo?.TrackName}</p>
          <p>Display: {sessionInfo?.WeekendInfo?.TrackDisplayName}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-1">
      <svg 
        width={420}
        height={320}
        viewBox="0 0 420 320"
        className="opacity-100 hover:opacity-100 transition-opacity duration-200"
      >
        {/* Background */}
        <rect 
          width={410} 
          height={320} 
          fill="rgba(0,0,0,0.3)" 
          
        />
        
        <g transform={trackPaths.transform}>
          {/* Track outline */}
          <path
            d={trackPaths.mainPath}
            stroke="#FFFFFF"
            strokeWidth="12"
            strokeOpacity="0.15"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          
          {/* Track main line */}
          <path
            d={trackPaths.mainPath}
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeOpacity="0.4"
            fill="none"
          />

          {/* Pit lane if available */}
          {trackPaths.pitPath && (
            <path
              d={trackPaths.pitPath}
              stroke="#FFFFFF"
              strokeWidth="1"
              strokeOpacity="0.15"
              fill="none"
            />
          )}
        </g>

        {/* Comparison points */}
        {renderComparisonPoints()}

        {/* Current position */}
        {renderCurrentPosition()}

        {/* Info */}
        <text x="10" y="20" fill="white" className="text-xs opacity-50">
          {sessionInfo.WeekendInfo.TrackDisplayName}
          {sessionInfo.WeekendInfo.TrackConfigName && 
            ` (${sessionInfo.WeekendInfo.TrackConfigName})`}
        </text>
      </svg>
    </div>
  );
};

export default TrackMap;