import React from 'react';
import { 
  LineChart, 
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const ShockChart = ({ data = [], config }) => {
    const processedData = React.useMemo(() => {
      if (!Array.isArray(data?.left) || !Array.isArray(data?.right) || !data.left.length || !data.right.length) return [];
      const step = Math.max(1, Math.floor(data.left.length / 500));
      return data.left.filter((_, i) => i % step === 0).map((item, index) => ({
        time: item.distance,
        left: Number(item.value).toFixed(2),
        right: Number(data.right[index]?.value).toFixed(2)
      }));
    }, [data]);
  
    if (!processedData.length) return null;
  
    const allValues = processedData.flatMap(d => [parseFloat(d.left), parseFloat(d.right)].filter(Boolean));
    const minValue = Math.floor(Math.min(...allValues));
    const maxValue = Math.ceil(Math.max(...allValues));
    
    const tickStep = 5;
    const ticks = [];
    for (let i = Math.floor(minValue/tickStep)*tickStep; i <= Math.ceil(maxValue/tickStep)*tickStep; i += tickStep) {
      ticks.push(i);
    }
  
    return (
      <div className="bg-opacity-20 backdrop-blur-sm bg-gray-900 rounded-lg p-4">
        <h3 className="text-[#f8f0f8] mb-3 font-bold tracking-wide text-sm">{config.title}</h3>
        <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <LineChart 
              data={processedData} 
              margin={{ top: 5, right: 20, bottom: 20, left: 10 }}
            >
              <CartesianGrid 
                strokeDasharray="3 3" 
                stroke="rgba(255,255,255,0.1)"
                vertical={true}
              />
              <XAxis 
                dataKey="time"
                type="number"
                domain={[0, 'maxData']}
                ticks={[0, 0.2, 0.4, 0.6, 0.8, 1.0]}
                tickFormatter={(value) => value.toFixed(1)}
                stroke="#88A0A8"
                axisLine={{ stroke: '#88A0A8', strokeWidth: 1 }}
                tickLine={false}
                dy={10}
                tick={{ fill: '#88A0A8', fontSize: 11 }}
              />
              <YAxis 
                domain={[minValue, maxValue]}
                ticks={ticks}
                tickFormatter={(value) => value}
                stroke="#88A0A8"
                orientation="left"
                axisLine={{ stroke: '#88A0A8', strokeWidth: 1 }}
                tickLine={false}
                interval={0}
                tick={{ fill: '#88A0A8', fontSize: 11 }}
                width={60}
              />
              <Tooltip 
                content={({ active, payload }) => {
                  if (!active || !payload?.length) return null;
                  return (
                    <div className="bg-black/80 backdrop-blur-sm border border-gray-800 p-2 rounded">
                      <p className="text-[#f8f0f8] text-xs">
                      </p>
                      <p className="text-[#f8f0f8] text-xs">
                        Left: {payload[0].value}mm
                      </p>
                      {payload[1] && (
                        <p className="text-[#f8f0f8] text-xs">
                          Right: {payload[1].value}mm
                        </p>
                      )}
                    </div>
                  );
                }}
              />
              <Line 
                type="monotone" 
                dataKey="left"
                stroke="#ff69b4"
                dot={false}
                strokeWidth={1.5}
                isAnimationActive={false}
              />
              <Line 
                type="monotone" 
                dataKey="right"
                stroke="#9932cc"
                dot={false}
                strokeWidth={1.5}
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="text-center text-xs text-[#88A0A8] -m-6">
          Shock Deflection [mm] (Light - Left / Dark - Right)
        </div>
      </div>
    );
  };

  const ShockHistogram = ({ data = [], config }) => {
    const processedData = React.useMemo(() => {
      if (!Array.isArray(data?.left) || !Array.isArray(data?.right) || !data.left.length || !data.right.length) return [];
      
      // Process the data for histogram
      const bins = new Array(80).fill(0);
      const maxVelocity = 0.18; // 180 mm/s = 0.18 m/s
      const binSize = maxVelocity * 2 / bins.length;
  
      // Process left data
      data.left.forEach(item => {
        const velocity = item.value;
        const binIndex = Math.floor((velocity + maxVelocity) / binSize);
        if (binIndex >= 0 && binIndex < bins.length) {
          bins[binIndex]++;
        }
      });
  
      // Process right data
      const rightBins = new Array(80).fill(0);
      data.right.forEach(item => {
        const velocity = item.value;
        const binIndex = Math.floor((velocity + maxVelocity) / binSize);
        if (binIndex >= 0 && binIndex < rightBins.length) {
          rightBins[binIndex]++;
        }
      });
  
      // Convert to percentage
      const leftTotal = data.left.length;
      const rightTotal = data.right.length;
      
      return Array(80).fill().map((_, i) => ({
        velocity: (-180 + (i * 4.5)),
        left: (bins[i] / leftTotal) * 100,
        right: (rightBins[i] / rightTotal) * 100
      }));
    }, [data]);
  
    if (!processedData.length) return null;
  
    // Find max value for Y axis
    const allValues = processedData.flatMap(d => [d.left, d.right]);
    const maxValue = Math.ceil(Math.max(...allValues));
    
    // Create Y axis ticks
    const yAxisMax = Math.ceil(maxValue * 1.2);
    const yAxisTicks = Array.from(
      { length: 6 }, 
      (_, i) => Number((yAxisMax * i / 5).toFixed(1))
    );
  
    return (
      <div className="bg-opacity-20 backdrop-blur-sm bg-gray-900 rounded-lg p-4">
        <h3 className="text-[#f8f0f8] mb-3 font-bold tracking-wide text-sm">{config.title}</h3>
        <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <BarChart 
              data={processedData} 
              margin={{ top: 5, right: 20, bottom: 20, left: 10 }}
              barGap={0}
            >
              <CartesianGrid 
                strokeDasharray="3 3" 
                stroke="rgba(255,255,255,0.1)"
                vertical={true}
              />
              <XAxis 
                dataKey="velocity"
                type="number"
                domain={[-180, 180]}
                ticks={[-150, -100, -50, 0, 50, 100, 150]}
                tickFormatter={(value) => Math.abs(value)}
                stroke="#88A0A8"
                axisLine={{ stroke: '#88A0A8', strokeWidth: 1 }}
                tickLine={false}
                dy={10}
                tick={{ fill: '#88A0A8', fontSize: 11 }}
              />
              <YAxis 
                domain={[0, yAxisMax]}
                ticks={yAxisTicks}
                tickFormatter={(value) => `${value}%`}
                stroke="#88A0A8"
                orientation="left"
                axisLine={{ stroke: '#88A0A8', strokeWidth: 1 }}
                tickLine={false}
                interval={0}
                tick={{ fill: '#88A0A8', fontSize: 11 }}
                width={60}
              />
              <Tooltip 
                content={({ active, payload }) => {
                  if (!active || !payload?.length) return null;
                  return (
                    <div className="bg-black/80 backdrop-blur-sm border border-gray-800 p-2 rounded">
                      <p className="text-[#f8f0f8] text-xs">
                        Velocity: {payload[0].payload.velocity.toFixed(2)}
                      </p>
                      <p className="text-[#f8f0f8] text-xs">
                        Left: {payload[0].value.toFixed(2)}%
                      </p>
                      {payload[1] && (
                        <p className="text-[#f8f0f8] text-xs">
                          Right: {payload[1].value.toFixed(2)}%
                        </p>
                      )}
                    </div>
                  );
                }}
              />
              <Bar 
                dataKey="left"
                fill="#ff69b4"
                opacity={0.8}
                minPointSize={3}
              />
              <Bar 
                dataKey="right"
                fill="#9932cc"
                opacity={0.8}
                minPointSize={3}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="p-2 text-center text-xs text-[#88A0A8] mt-2">
          Shock Deflection [mm] (Light - Left / Dark - Right)
        </div>
      </div>
    );
  };

const ShocksTab = ({ data, selectedLap = 0 }) => {
  const lapData = React.useMemo(() => {
    return data?.laps?.[selectedLap];
  }, [data?.laps, selectedLap]);

  if (!lapData?.dampers) {
    return (
      <div className="flex items-center justify-center h-full text-gray-400">
        No damper data available for this lap
      </div>
    );
  }

  const { FL, FR, LR, RR } = lapData.dampers;

  const posConfig = {
    domain: [-50, 50],
    ticks: [-50, -25, 0, 25, 50],
    formatY: (value) => `${value.toFixed(1)}mm`,
  };

  return (
    <div className="bg-opacity-10 backdrop-blur-sm p-8">
      {/* Positions */}
      <h2 className="text-[#FFFFFF] font-bold text-lg mb-4">DAMPER POSITIONS</h2>
      <div className="grid grid-rows-2 gap-6 h-screen">
  <ShockChart 
    data={{
      left: FL?.position,
      right: FR?.position
    }}
    config={{
      title: "Front Shock Position"
    }}
  />
  <ShockChart
    data={{
      left: LR?.position,
      right: RR?.position
    }}
    config={{
      title: "Rear Shock Position"
    }}
  />
</div>

      {/* Velocities */}
      <h2 className="p-5 text-[#FFFFFF] font-bold text-lg mb-4">DAMPER VELOCITIES</h2>
      <div className="grid grid-cols-2 gap-6">
  <ShockHistogram
    data={{
      left: FL?.velocity,
      right: FR?.velocity
    }}
    config={{ title: "Front Shock Velocity" }}
  />
  <ShockHistogram
    data={{
      left: LR?.velocity,
      right: RR?.velocity
    }}
    config={{ title: "Rear Shock Velocity" }}
  />
</div>
    </div>
  );
};

export default ShocksTab;