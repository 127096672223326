import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { authService } from '../../services/authService';
import EmailVerificationModal from './EmailVerificationModal';

const LoginForm = ({ onSwitchToRegister }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const { login, resetPassword } = useAuth();

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    const savedPassword = localStorage.getItem('rememberedPassword');
    
    if (savedEmail && savedPassword) {
      setFormData({
        email: savedEmail,
        password: atob(savedPassword)
      });
      setRememberMe(true);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (error) setError('');
  };

  const handleResendVerification = async () => {
    try {
      await authService.resendVerificationEmail(formData.email, formData.password);
      return true;
    } catch (error) {
      console.error('Erro ao reenviar:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    try {
      await login(formData.email, formData.password);
  
      if (rememberMe) {
        localStorage.setItem('rememberedEmail', formData.email);
        localStorage.setItem('rememberedPassword', btoa(formData.password));
      } else {
        localStorage.removeItem('rememberedEmail');
        localStorage.removeItem('rememberedPassword');
      }
    } catch (error) {
      console.error('Erro no login:', error);
      
      // Verificar erro de email não verificado
      if (error.code === 'auth/email-not-verified' || error.message.includes('verifique seu email')) {
        setShowVerificationModal(true);
      } else {
        setError(error.message || 'Email ou senha inválidos');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!formData.email || !/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
        setError('Por favor, insira um email válido.');
        return;
    }

    try {
        setLoading(true);
        await resetPassword(formData.email);
        alert('Email de recuperação de senha enviado. Verifique sua caixa de entrada.');
    } catch (error) {
        console.error('Erro ao enviar email de recuperação:', error); // Log do erro real

        // Exibe mensagem personalizada com base no erro retornado
        if (error.code === 'auth/user-not-found') {
            setError('Nenhum usuário encontrado com este email.');
        } else if (error.code === 'auth/invalid-email') {
            setError('O email fornecido é inválido.');
        } else if (error.code === 'auth/too-many-requests') {
            setError('Muitas tentativas recentes. Tente novamente mais tarde.');
        } else {
            setError(error.message || 'Erro ao enviar email de recuperação.');
        }
    } finally {
        setLoading(false);
    }
};


  return (
    <div className="w-full max-w-sm bg-gray-800 rounded-lg shadow-xl p-4 pt-2">
      {/* Logo Container - ajustado para preencher mais o espaço */}
      <div className="mb-4">
        <div className="logo-container w-full">
          <img 
            src="/assets/logo_isetup1.png" 
            alt="iSetup" 
            className="mx-auto h-36" // Ajustado para usar largura total com padding
          />
          <div className="h-px bg-gray-700 w-full"></div>
        </div>
      </div>

      <h2 className="text-2xl font-bold mb-6 text-center text-white">Login</h2>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1 text-white">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 text-white focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
            autoComplete="email"
            placeholder="Digite seu email"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1 text-white">Senha</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 text-white focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
            autoComplete="current-password"
            placeholder="Digite sua senha"
          />
        </div>

        <div className="flex justify-between items-center">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              className="rounded border-gray-600 text-blue-500 focus:ring-blue-500 mr-2"
            />
            <span className="text-sm text-white">Lembrar senha</span>
          </label>
          
          <button
            type="button"
            onClick={handleForgotPassword}
            className="text-sm text-blue-500 hover:text-blue-400"
          >
            Esqueceu a senha?
          </button>
        </div>

        {error && (
          <div className="text-red-500 text-sm text-center">
            {error}
          </div>
        )}

        <button
          type="submit"
          disabled={loading}
          className={`w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {loading ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Entrando...
            </>
          ) : (
            'Entrar'
          )}
        </button>
      </form>

      <div className="mt-4 text-center text-white">
        Não tem conta?{' '}
        <button
          type="button"
          onClick={onSwitchToRegister}
          className="text-blue-500 hover:text-blue-400"
        >
          Registre-se
        </button>
      </div>

      {showVerificationModal && (
        <EmailVerificationModal
          email={formData.email}
          onClose={() => setShowVerificationModal(false)}
          onResendEmail={handleResendVerification}
        />
      )}
    </div>
);
};

export default LoginForm;