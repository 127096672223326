// src/services/ibtParser/yamlParser.js

const REGEX = {
  NUMBER: /^-?\d*\.?\d+$/,
  NUMBER_WITH_UNIT: /^(-?\d*\.?\d+)\s*([a-zA-Z/%°]+)?$/,
  QUOTED_STRING: /^["'](.*)["']$/,
  YAML_PATH: /^([^{]+)(?:{(\d+)})?$/
};

function parseValue(value) {
  if (!value) return null;
  
  if (typeof value === 'string') {
    // Remove aspas
    const quotedMatch = value.match(REGEX.QUOTED_STRING);
    if (quotedMatch) {
      value = quotedMatch[1];
    }

    // Número com unidade
    const unitMatch = value.match(REGEX.NUMBER_WITH_UNIT);
    if (unitMatch) {
      const [_, number, unit] = unitMatch;
      // Se a unidade for km, retorna direto o número
      if (unit && unit.toLowerCase().includes('km')) {
        return parseFloat(number);
      }
      return {
        value: parseFloat(number),
        unit: unit || null
      };
    }

    // Número simples
    if (REGEX.NUMBER.test(value)) {
      return parseFloat(value);
    }
  }

  return value;
}

function extractValue(yamlData, path, defaultValue = null) {
  try {
    if (!yamlData || !path) return defaultValue;

    const parts = path.split(':');
    let current = yamlData;

    for (const part of parts) {
      const match = part.match(REGEX.YAML_PATH);
      if (!match) continue;

      const [_, key, arrayIndex] = match;
      current = current[key.trim()];
      
      if (current === undefined) return defaultValue;

      if (arrayIndex !== undefined) {
        const index = parseInt(arrayIndex, 10);
        if (!Array.isArray(current)) return defaultValue;
        current = current[index];
        if (current === undefined) return defaultValue;
      }
    }

    // Se o valor for um objeto com value/unit
    if (current && typeof current === 'object') {
      if ('value' in current) {
        return current.value;
      }
    }

    const parsedValue = parseValue(current);
    // Se o valor parseado for um objeto com value/unit
    if (parsedValue && typeof parsedValue === 'object' && 'value' in parsedValue) {
      return parsedValue.value;
    }

    return parsedValue ?? defaultValue;
  } catch (error) {
    console.warn('Erro ao extrair valor do YAML:', error);
    return defaultValue;
  }
}

function processDriversSection(lines, startIndex) {
  const drivers = [];
  let currentDriver = null;
  let baseIndent = -1;
  let insideDrivers = false;

  for (let i = startIndex; i < lines.length; i++) {
    const line = lines[i];
    if (!line.trim()) continue;

    const indent = line.search(/\S/);
    if (indent === -1) continue;

    // Define indentação base na primeira linha
    if (baseIndent === -1) {
      baseIndent = indent;
    }

    // Se encontrar uma linha com indentação menor que a base, saímos da seção
    if (indent < baseIndent && insideDrivers) {
      break;
    }

    const trimmedLine = line.trim();

    // Início de um novo driver
    if (trimmedLine.startsWith('- CarIdx:')) {
      if (currentDriver) {
        drivers.push(currentDriver);
      }
      currentDriver = {};
      insideDrivers = true;

      // Extrai o CarIdx
      const carIdxValue = trimmedLine.split(':')[1].trim();
      currentDriver.CarIdx = parseInt(carIdxValue, 10);
    }
    // Propriedades do driver atual
    else if (currentDriver && trimmedLine.includes(':') && !trimmedLine.startsWith('-')) {
      const [key, ...valueParts] = trimmedLine.split(':');
      let value = valueParts.join(':').trim();
      
      // Remove aspas se presentes
      value = value.replace(/^["']|["']$/g, '');
      
      // Trata valores especiais
      if (value === 'true' || value === 'false') {
        value = value === 'true';
      } else if (!isNaN(value) && value !== '') {
        value = parseFloat(value);
      }

      const keyName = key.trim();
      currentDriver[keyName] = value;
    }
  }

  // Adiciona o último driver
  if (currentDriver) {
    drivers.push(currentDriver);
  }

  return drivers;
}

function parseSetupSection(lines, startIndex) {
  const sections = {
    TiresAero: {},
    Chassis: {},
    GearsDifferential: {},
    Dampers: {}
  };

  let currentMainSection = '';
  let currentSubSection = '';
  let baseIndent = -1;

  function getIndentLevel(line) {
    return line.search(/\\S/);
  }

  function assignValue(section, subsection, key, value) {
    if (!sections[section]) sections[section] = {};
    if (subsection) {
      if (!sections[section][subsection]) sections[section][subsection] = {};
      sections[section][subsection][key] = value;
    } else {
      sections[section][key] = value;
    }
  }

  function processValue(value) {
    if (!value) return value;
    value = value.trim().replace(/^["']|["']$/g, '');

    if (value.includes(', ') || value.includes('Dry')) return value;
    if (value.includes('Low friction') || value.includes('Race') || 
        value.includes('FIA') || value.includes('White')) return value;

    const num = parseFloat(value);
    return isNaN(num) ? value : num;
  }

  for (let i = startIndex; i < lines.length; i++) {
    const line = lines[i];
    if (!line.trim()) continue;

    const indent = getIndentLevel(line);
    if (baseIndent === -1) baseIndent = indent;
    
    const trimmedLine = line.trim();
    if (trimmedLine.startsWith('#')) continue;

    // Detecta seções principais
    if (trimmedLine === 'TiresAero:') {
      currentMainSection = 'TiresAero';
      currentSubSection = '';
      continue;
    } else if (trimmedLine === 'Chassis:') {
      currentMainSection = 'Chassis';
      currentSubSection = '';
      continue;
    } else if (trimmedLine === 'GearsDifferential:') {
      currentMainSection = 'GearsDifferential';
      currentSubSection = '';
      continue;
    } else if (trimmedLine === 'Dampers:') {
      currentMainSection = 'Dampers';
      currentSubSection = '';
      continue;
    }

    // Detecta subseções
    if (trimmedLine.endsWith(':')) {
      currentSubSection = trimmedLine.slice(0, -1);
      continue;
    }

    // Processa valores
    const colonIndex = trimmedLine.indexOf(':');
    if (colonIndex > 0) {
      const key = trimmedLine.slice(0, colonIndex).trim();
      const value = processValue(trimmedLine.slice(colonIndex + 1));

      if (currentMainSection) {
        assignValue(currentMainSection, currentSubSection, key, value);
      }
    }
  }

  // Estrutura final
  return {
    TiresAero: {
      TireType: sections.TiresAero.TireType || { TireType: 'Dry' },
      LeftFront: sections.TiresAero.LeftFront || {},
      RightFront: sections.TiresAero.RightFront || {},
      LeftRear: sections.TiresAero.LeftRear || {},
      RightRear: sections.TiresAero.RightRear || {},
      AeroBalanceCalc: sections.TiresAero.AeroBalanceCalc || {}
    },
    Chassis: {
      Front: sections.Chassis.Front || {},
      LeftFront: sections.Chassis.LeftFront || {},
      RightFront: sections.Chassis.RightFront || {},
      LeftRear: sections.Chassis.LeftRear || {},
      RightRear: sections.Chassis.RightRear || {},
      Rear: sections.Chassis.Rear || {},
      InCarDials: sections.Chassis.InCarDials || {}
    },
    GearsDifferential: sections.GearsDifferential || {},
    Dampers: {
      FrontDampers: sections.Dampers.FrontDampers || {},
      RearDampers: sections.Dampers.RearDampers || {}
    }
  };
}

export function parseYamlData(yamlString) {
  if (!yamlString) {
    console.warn('String YAML vazia ou inválida');
    return {};
  }

  try {

    const lines = yamlString.split('\n');
    let yamlData = {};
    let currentSection = null;
    let currentSubSection = null;
    let setupStartIndex = -1;
    let driversStartIndex = -1;

    // Primeiro passo: identifica seções especiais e processa estrutura básica
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (!line.trim() || line.trim().startsWith('#')) continue;
      if (line.trim() === '---' || line.trim() === '...') continue;

      const indent = line.search(/\S/);
      if (indent === -1) continue;

      const trimmedLine = line.trim();

      if (trimmedLine === 'CarSetup:') {
        setupStartIndex = i + 1;
        continue;
      }

      if (trimmedLine === 'Drivers:' && currentSection === 'DriverInfo') {
        driversStartIndex = i + 1;
        continue;
      }

      if (trimmedLine.endsWith(':')) {
        const sectionName = trimmedLine.slice(0, -1).trim();
        
        if (indent === 0) {
          currentSection = sectionName;
          currentSubSection = null;
          yamlData[currentSection] = {};
        } else {
          currentSubSection = sectionName;
          if (!yamlData[currentSection][currentSubSection]) {
            yamlData[currentSection][currentSubSection] = {};
          }
        }
        continue;
      }

      const colonIndex = trimmedLine.indexOf(':');
      if (colonIndex > 0 && currentSection) {
        const key = trimmedLine.slice(0, colonIndex).trim();
        let value = trimmedLine.slice(colonIndex + 1).trim();

        value = value.replace(/^["']|["']$/g, '');
        
        if (currentSubSection) {
          yamlData[currentSection][currentSubSection][key] = parseValue(value);
        } else {
          yamlData[currentSection][key] = parseValue(value);
        }
      }
    }

    // Processa seções especiais
    const carSetup = setupStartIndex > 0 ? parseSetupSection(lines, setupStartIndex) : null;
    const drivers = driversStartIndex > 0 ? processDriversSection(lines, driversStartIndex) : [];

    // Logs para debug
    

    // Monta o resultado final
    const result = {
      WeekendInfo: yamlData.WeekendInfo || {},
      DriverInfo: {
        DriverUserID: extractValue(yamlData, 'DriverInfo:DriverUserID'),
        DriverCarIdx: extractValue(yamlData, 'DriverInfo:DriverCarIdx'),
        Drivers: drivers,
      },
      SessionInfo: yamlData.SessionInfo || {},
      CarSetup: carSetup,
      weather: {
        temperature: extractValue(yamlData, 'WeekendInfo:AirTemp', 0),
        trackTemp: extractValue(yamlData, 'WeekendInfo:TrackTemp', 0),
        humidity: extractValue(yamlData, 'WeekendInfo:RelativeHumidity', 0),
        windSpeed: extractValue(yamlData, 'WeekendInfo:WindVel', 0),
        windDir: extractValue(yamlData, 'WeekendInfo:WindDir', 0),
        skies: extractValue(yamlData, 'WeekendInfo:Skies', 'Clear')
      },
      track: {
        name: extractValue(yamlData, 'WeekendInfo:TrackDisplayName', ''),
        config: extractValue(yamlData, 'WeekendInfo:TrackConfigName', ''),
        length: extractValue(yamlData, 'WeekendInfo:TrackLength', 0),
        type: extractValue(yamlData, 'WeekendInfo:TrackType', '')
      }
    };

    return result;

  } catch (error) {
    return {
      WeekendInfo: {},
      DriverInfo: { 
        DriverUserID: null, 
        DriverCarIdx: null,
        Drivers: [] 
      },
      SessionInfo: {},
      CarSetup: null,
      weather: {},
      track: {}
    };
  }
}

export {
  parseValue,
  extractValue,
  parseSetupSection,
  processDriversSection
};