// src/components/SEO/index.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

const DEFAULT_SEO = {
  title: 'iSetup - Configurações para iRacing',
  description: 'Gerencie e otimize suas configurações de corrida no iRacing com o iSetup. Análise de telemetria, ajustes de setup e mais.',
  keywords: 'iracing, setup, telemetria, corrida virtual, simulação',
  author: 'iSetup',
  type: 'website',
  image: '/assets/logo_isetup1.png',
  url: 'https://isetup.xyz', // Substitua pela URL real do seu site
};

const SEO = ({ 
  title = DEFAULT_SEO.title,
  description = DEFAULT_SEO.description,
  keywords = DEFAULT_SEO.keywords,
  author = DEFAULT_SEO.author,
  type = DEFAULT_SEO.type,
  image = DEFAULT_SEO.image,
  url = DEFAULT_SEO.url,
  noindex = false,
  children,
}) => {
  const siteTitle = title === DEFAULT_SEO.title ? title : `${title} | iSetup`;

  return (
    <Helmet>
      {/* Metadados básicos */}
      <title>{siteTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      
      {/* Controle de indexação */}
      {noindex && <meta name="robots" content="noindex,nofollow" />}
      
      {/* Open Graph - Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      
      {/* Links canônicos e alternados */}
      <link rel="canonical" href={url} />
      
      {children}
    </Helmet>
  );
};

export default SEO;