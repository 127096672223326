// src/services/ibtParser/ibtParser.js
import { parseYamlData } from '../services/ibtParser/yamlParser';

class IbtParser {
  constructor() {
    this.debug = false;
    this.dataView = null;
    this.offset = 0;
  }

  setDebug(enabled) {
    this.debug = enabled;
  }

  log(...args) {
    if (this.debug) {
    }
  }

  async parseFile(file) {
    try {
      this.log('Iniciando parse do arquivo:', file.name);
      const buffer = await file.arrayBuffer();
      this.dataView = new DataView(buffer);
      this.offset = 0;

      // Lê o header principal
      const header = {
        version: this.readInt32(),
        status: this.readInt32(),
        tickRate: this.readInt32(),
        sessionInfoUpdate: this.readInt32(),
        sessionInfoLen: this.readInt32(),
        sessionInfoOffset: this.readInt32(),
        numVars: this.readInt32(),
        varHeaderOffset: this.readInt32(),
        numBuf: this.readInt32(),
        bufLen: this.readInt32()
      };

      // Pula 8 bytes de padding
      this.offset += 8;

      // Lê informações do buffer
      const tickCount = this.readInt32();
      const bufOffset = this.readInt32();

      // Pula buffers extras
      this.offset += 24;

      // Lê sub-header do disco
      const sessionStartDate = new Date(this.readFloat64() * 1000);
      const sessionStartTime = this.readFloat64();
      const sessionEndTime = this.readFloat64();
      const sessionLapCount = this.readInt32();
      const sessionRecordCount = this.readInt32();

      this.log('Header principal lido:', header);
      this.log('Record count:', sessionRecordCount);

      // Lê a string YAML da sessão
      this.offset = header.sessionInfoOffset;
      const sessionYaml = this.readString(header.sessionInfoLen);

      // Lê os headers das variáveis
      this.offset = header.varHeaderOffset;
      const varHeaders = new Map();
      
      for (let i = 0; i < header.numVars; i++) {
        const varHeader = {
          type: this.readInt32(),
          offset: this.readInt32(),
          count: this.readInt32(),
          countAsTime: this.readBool(),
          name: '',
          desc: '',
          unit: ''
        };

        // Pula 3 bytes de padding após o bool
        this.offset += 3;

        varHeader.name = this.readString(32);
        varHeader.desc = this.readString(64);
        varHeader.unit = this.readString(32);

        if (varHeader.name) {
          varHeaders.set(varHeader.name, varHeader);
        }
      }

      this.log('Headers das variáveis lidos:', Array.from(varHeaders.keys()));

      // Lê os dados de telemetria
      const telemetryData = [];
      this.offset = bufOffset;

      // Calcula o número real de registros
      const dataSize = buffer.byteLength - bufOffset;
      const recordCount = Math.floor(dataSize / header.bufLen);
      this.log('Calculado record count:', recordCount);

      for (let i = 0; i < recordCount; i++) {
        const frame = {};
        for (const [name, varHeader] of varHeaders.entries()) {
          const dataOffset = this.offset + varHeader.offset;
          frame[name] = this.readValue(dataOffset, varHeader.type);
        }
        telemetryData.push(frame);
        this.offset += header.bufLen;
      }

      // Processa os dados em voltas
      const laps = [];
      let currentLap = [];
      let lastLapNumber = -1;

      // Parse do YAML e extração dos dados da sessão
      const sessionData = parseYamlData(sessionYaml);
      this.log('Parsed Session Data:', sessionData);

      // Processa voltas
      for (const frame of telemetryData) {
        const lapNumber = frame.Lap || 0;
        
        if (lapNumber !== lastLapNumber) {
          if (currentLap.length > 0) {
            const processedLap = this.processLap(currentLap);
            if (processedLap) {
              laps.push(processedLap);
            }
          }
          currentLap = [];
          lastLapNumber = lapNumber;
        }
        
        if (lapNumber > 0) {
          currentLap.push(frame);
        }
      }

      // Processa última volta
      if (currentLap.length > 0) {
        const processedLap = this.processLap(currentLap);
        if (processedLap) {
          laps.push(processedLap);
        }
      }

      this.log('Voltas processadas:', laps.length);

      // Encontra o driver proprietário da telemetria
      const driverUserID = sessionData?.DriverInfo?.DriverUserID;
      const driverCarIdx = sessionData?.DriverInfo?.DriverCarIdx;
      
      this.log('Driver User ID:', driverUserID);
      this.log('Driver Car Index:', driverCarIdx);

      // Encontra o driver correto no array de drivers
      const telemetryOwner = sessionData?.DriverInfo?.Drivers?.find(driver => 
        Number(driver.CarIdx) === Number(driverCarIdx) || 
        Number(driver.UserID) === Number(driverUserID)
      );

      this.log('Driver proprietário da telemetria:', telemetryOwner);
      const currentSession = sessionData?.SessionInfo?.Sessions?.[0] || {};

      // Retorna a estrutura final dos dados
      return {
        sessionInfo: {
          WeekendInfo: sessionData?.WeekendInfo,
          SessionInfo: {
            Sessions: sessionData?.SessionInfo?.Sessions || [],
            SessionNum: currentSession?.SessionNum,
            SessionType: currentSession?.SessionType,
            SessionName: currentSession?.SessionName,
            SessionTrackRubberState: currentSession?.SessionTrackRubberState
          },
          DriverInfo: {
            DriverUserID: driverUserID,
            DriverCarIdx: driverCarIdx,
            Drivers: sessionData?.DriverInfo?.Drivers || [],
            TelemetryOwner: telemetryOwner ? {
              UserName: telemetryOwner.UserName,
              UserID: telemetryOwner.UserID,
              CarScreenName: telemetryOwner.CarScreenName,
              CarPath: telemetryOwner.CarPath,
              CarClassShortName: telemetryOwner.CarClassShortName,
              IRating: parseInt(telemetryOwner.IRating) || 0,
              LicLevel: parseInt(telemetryOwner.LicLevel) || 0,
              LicSubLevel: parseInt(telemetryOwner.LicSubLevel) || 0
            } : null
          },
          CarSetup: sessionData.CarSetup,
          session: {
            type: sessionData?.WeekendInfo?.EventType || 'Race',
            trackName: sessionData?.WeekendInfo?.TrackDisplayName || 'Unknown Track',
            trackConfig: sessionData?.WeekendInfo?.TrackConfigName || '',
            trackLength: parseFloat(sessionData?.WeekendInfo?.TrackLength) || 0,
            carName: telemetryOwner?.CarScreenName || 'Unknown Car',
            carClass: telemetryOwner?.CarClassShortName || '',
          },
          driver: {
            driverName: telemetryOwner?.UserName || 'Unknown Driver',
            userId: driverUserID,
            iRating: telemetryOwner?.IRating || 0,
            licenseLevel: telemetryOwner?.LicLevel || 0,
            licenseSubLevel: telemetryOwner?.LicSubLevel || 0
          },
          weather: {
            temperature: parseFloat(sessionData?.weather?.temperature) || 0,
            trackTemp: parseFloat(sessionData?.weather?.trackTemp) || 0,
            humidity: parseFloat(sessionData?.weather?.humidity) || 0,
            windSpeed: parseFloat(sessionData?.weather?.windSpeed) || 0,
            windDir: parseFloat(sessionData?.weather?.windDir) || 0
          },
          timing: {
            timeStarted: sessionStartTime,
            timeEnded: sessionEndTime,
            lapCount: sessionLapCount,
            recordCount: recordCount,
            tickRate: header.tickRate
          }
        },
        laps
      };

    } catch (error) {
      this.log('Erro durante o parse:', error);
      throw error;
    }
  }

  readInt32() {
    const value = this.dataView.getInt32(this.offset, true);
    this.offset += 4;
    return value;
  }

  readFloat32() {
    const value = this.dataView.getFloat32(this.offset, true);
    this.offset += 4;
    return value;
  }

  readFloat64() {
    const value = this.dataView.getFloat64(this.offset, true);
    this.offset += 8;
    return value;
  }

  readBool() {
    const value = this.dataView.getInt8(this.offset) !== 0;
    this.offset += 1;
    return value;
  }

  readString(maxLength) {
    const bytes = new Uint8Array(this.dataView.buffer, this.offset, maxLength);
    const nullIndex = bytes.indexOf(0);
    const length = nullIndex !== -1 ? nullIndex : maxLength;
    const text = new TextDecoder().decode(bytes.slice(0, length));
    this.offset += maxLength;
    return text.trim();
  }

  readValue(offset, type) {
    switch (type) {
      case 1: // bool
        return this.dataView.getInt8(offset) !== 0;
      case 2: // int
      case 3: // bitfield
        return this.dataView.getInt32(offset, true);
      case 4: // float
        return this.dataView.getFloat32(offset, true);
      case 5: // double
        return this.dataView.getFloat64(offset, true);
      default:
        return 0;
    }
  }

  processLap(frames) {
    if (!frames.length) return null;

    const firstFrame = frames[0];
    const lastFrame = frames[frames.length - 1];

    return {
      number: firstFrame.Lap,
      time: lastFrame.SessionTime - firstFrame.SessionTime,
      speed: frames.map(frame => ({
        distance: frame.LapDist,
        value: (frame.Speed || 0) * 3.6,
        sessionTime: frame.SessionTime,
        lateralPosition: frame.LateralPosition || 0,     
        trackPosition: frame.CarIdxTrackSurface || 0,  
        lapDistPct: frame.LapDistPct || 0
      })),
      inputs: frames.map(frame => ({
        distance: frame.LapDist,
        throttle: frame.Throttle || 0,
        brake: frame.Brake || 0,
        gear: frame.Gear,
        steering: frame.SteeringWheelAngle || 0,
        // Adicionando mesmos dados para inputs
        lateralPosition: frame.LateralPosition || 0,
        trackPosition: frame.CarIdxTrackSurface || 0,
        lapDistPct: frame.LapDistPct || 0
      })),
      deltas: frames.map(frame => ({
        distance: frame.LapDist,
        timeDelta: frame.LapDeltaToSessionBestLap || 0,
        lineDiff: frame.LapDist - (frame.LapDistPct || 0)
      })),
      dampers: {
        FL: {
          position: frames.map(frame => ({
            distance: frame.LapDist,
            value: (frame.LFshockDefl || 0) * 1000  // Convertendo para mm
          })),
          velocity: frames.map(frame => ({
            distance: frame.LapDist,
            value: (frame.LFshockVel || 0)  // Já está em m/s
          }))
        },
        FR: {
          position: frames.map(frame => ({
            distance: frame.LapDist,
            value: (frame.RFshockDefl || frame.CFshockDefl || 0) * 1000
          })),
          velocity: frames.map(frame => ({
            distance: frame.LapDist,
            value: (frame.RFshockVel || frame.CFshockVel || 0)
          }))
        },
        LR: {
          position: frames.map(frame => ({
            distance: frame.LapDist,
            value: (frame.LRshockDefl || 0) * 1000
          })),
          velocity: frames.map(frame => ({
            distance: frame.LapDist,
            value: (frame.LRshockVel || 0)
          }))
        },
        RR: {
          position: frames.map(frame => ({
            distance: frame.LapDist,
            value: (frame.RRshockDefl || frame.CRshockDefl || 0) * 1000
          })),
          velocity: frames.map(frame => ({
            distance: frame.LapDist,
            value: (frame.RRshockVel || frame.CRshockVel || 0)
          }))
        }
      }
    };
  }
}

export default IbtParser;