// TelemetryDashboard.jsx
import React, { useState, useCallback } from 'react';
import { 
  Upload, 
  ChevronRight, 
  AlertCircle, 
  Loader, 
  FileJson, 
  FileDown,
  MapPin, 
  Route, 
  Thermometer, 
  ThermometerSun, 
  Cloud, 
  CloudRain, 
  Droplets, 
  Wind,
  CloudFog,
  Compass, 
    CircleDot,
    Timer 
} from 'lucide-react';
import { 
  Alert, 
  AlertDescription, 
  AlertTitle 
} from '../../components/ui/alert';
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardTitle 
} from '../../components/ui/Card';
import { Badge } from '../../components/ui/badge';
import { Separator } from '../../components/ui/separator';
import createIbtParser from '../../services/ibtParser';
import TelemetryAnalyzer from './TelemetryAnalyzer';
// Utility functions for iRacing license
const getLicenseColor = (level) => {
  // Converte o nível de licença do iRacing (1-20) para nossa escala (0-5)
  const licLevel = Math.floor((parseInt(level) || 0) / 4);
  switch(licLevel) {
    case 0: return 'bg-red-500';    // Rookie (1-4)
    case 1: return 'bg-orange-500'; // D (5-8)
    case 2: return 'bg-yellow-500'; // C (9-12)
    case 3: return 'bg-green-500';  // B (13-16)
    case 4: return 'bg-blue-500';   // A (17-20)
    case 5: return 'bg-purple-500'; // Pro (>20)
    default: return 'bg-gray-500';
  }
};
const decodeHtmlEntities = (text) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};
const formatSessionName = (name) => {
  if (!name) return 'Unknown Session';
  
  const formattedName = name.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
  return formattedName.replace(/_/g, ' ');
};
const getLicenseName = (level) => {
  // Converte o nível de licença do iRacing (1-20) para nossa escala (0-5)
  const licLevel = Math.floor((parseInt(level) || 0) / 4);
  switch(licLevel) {
    case 0: return 'Rookie';
    case 1: return 'D';
    case 2: return 'C';
    case 3: return 'B';
    case 4: return 'A';
    case 5: return 'Pro';
    default: return 'Unknown';
  }
};

const formatLicense = (level, subLevel) => {
  const licenseName = getLicenseName(level);
  const safetyRating = (parseInt(subLevel || 0) / 100).toFixed(2);
  return `${licenseName} ${safetyRating}`;
};

// Interface de upload de arquivo
const FileUploader = ({ isProcessing, onFileSelect }) => {
  return (
    <Card className="bg-gray-800/50 backdrop-blur-sm">
      <CardContent className="pt-6 text-center">
        <div className="mb-6">
          <Upload size={48} className="mx-auto mb-4 text-blue-400" />
          <CardTitle className="mb-2">Upload de Telemetria</CardTitle>
          <CardDescription>
            Selecione um arquivo .ibt do iRacing para análise
          </CardDescription>
        </div>
        
        <label className={`
          inline-flex items-center px-4 py-2 rounded-lg cursor-pointer 
          transition-colors ${isProcessing 
            ? 'bg-blue-500/50 text-white cursor-not-allowed' 
            : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          <input
            type="file"
            accept=".ibt"
            onChange={onFileSelect}
            className="hidden"
            disabled={isProcessing}
          />
          <span className="flex items-center">
            {isProcessing ? (
              <>
                <Loader className="animate-spin mr-2" size={16} />
                Processando...
              </>
            ) : (
              <>
                Selecionar Arquivo
                <ChevronRight size={16} className="ml-2" />
              </>
            )}
          </span>
        </label>

        <div className="mt-6 flex flex-col items-center gap-2">
          <Badge variant="secondary" className="flex items-center gap-1">
            <FileJson size={12} />
            Arquivos .ibt
          </Badge>
          <p className="text-sm text-gray-500">
            Apenas arquivos de telemetria do iRacing são suportados
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

// Status do processamento
const ProcessingStatus = ({ file }) => {
  if (!file) return null;

  return (
    <Card className="bg-gray-700/50">
      <CardContent className="pt-6">
        <div className="flex items-start gap-4">
          <div className="flex-1">
            <h3 className="font-medium text-white mb-1">Arquivo Selecionado</h3>
            <div className="flex items-center gap-2">
              <FileDown size={16} className="text-gray-400" />
              <span className="text-gray-300">{file.name}</span>
            </div>
          </div>
          <div className="flex items-center justify-center text-blue-400">
            <Loader className="animate-spin mr-2" size={16} />
            <span>Analisando telemetria...</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};// Error Boundary para tratamento de erros
class TelemetryErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Telemetry Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Erro ao processar telemetria</AlertTitle>
          <AlertDescription>
            {this.state.error?.message || 'Ocorreu um erro inesperado'}
          </AlertDescription>
        </Alert>
      );
    }

    return this.props.children;
  }
}

// Componente de informações da sessão
const SessionInfo = ({ info }) => {
  
  if (!info) return null;

  const {
    session = {},
    driver = {},
    weather = {},
    timing = {}
  } = info;

  const formatTemperature = (value) => {
    const temp = typeof value === 'number' ? value : parseFloat(value);
    return !isNaN(temp) ? `${temp.toFixed(1)}°C` : '0.0°C';
  };

  const formatHumidity = (value) => {
    const hum = typeof value === 'number' ? value : parseFloat(value);
    return !isNaN(hum) ? `${Math.round(hum)}%` : '0%';
  };

  const formatWindSpeed = (value) => {
    const speed = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(speed)) return '0.0 km/h';
    const kmh = speed * 3.6;
    return `${kmh.toFixed(1)} km/h`;
  };

  const formatTrackLength = (length) => {
    if (!length || isNaN(length)) return '0.00 km';
    const numLength = typeof length === 'number' ? length : parseFloat(length);
    return `${numLength.toFixed(2)} km`;
  };

  const getSessionStatusBadge = (isOfficial) => {
    return isOfficial ? (
      <Badge variant="success" className="bg-green-500/10 text-green-500 border-green-500/20">
        Oficial
      </Badge>
    ) : (
      <Badge variant="secondary" className="bg-gray-500/10 text-gray-500 border-gray-500/20">
        Não Oficial
      </Badge>
    );
  };

  const getTrackConditionIcon = (isDynamic) => {
    return isDynamic ? (
      <Badge className="bg-blue-500/10 text-blue-500 border-blue-500/20">
        Pista Dinâmica
      </Badge>
    ) : (
      <Badge className="bg-gray-500/10 text-gray-500 border-gray-500/20">
        Pista Estática
      </Badge>
    );
  };

  const getSetupTypeBadge = (isFixed) => {
    return isFixed ? (
      <Badge className="bg-orange-500/10 text-orange-500 border-orange-500/20">
        Setup Fixo
      </Badge>
    ) : (
      <Badge className="bg-green-500/10 text-green-500 border-green-500/20">
        Setup Livre
      </Badge>
    );
  };

  return (
    <div className="grid gap-4">
      {/* Cabeçalho - Driver e Carro */}
      <Card className="bg-gray-800/50 backdrop-blur-sm">
        <CardContent className="pt-6">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
            <div className="space-y-2">
              <h2 className="text-2xl font-bold text-white">
                {driver.driverName || 'Unknown Driver'}
              </h2>
              <div className="flex flex-wrap items-center gap-2">
                <Badge 
                  className={`${getLicenseColor(driver.licenseLevel)} text-white font-medium px-3 py-1`}
                >
                  {formatLicense(driver.licenseLevel, driver.licenseSubLevel)}
                </Badge>
                <Badge 
                  variant="outline" 
                  className="font-mono bg-violet-500/10 text-violet-400 border-violet-500/20 px-3 py-1"
                >
                  iRating {parseInt(driver.iRating || 0).toString()}
                </Badge>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <p className="text-lg font-medium text-white">
                {session.carName || 'Unknown Car'}
              </p>
              {session.carClass && (
                <Badge variant="outline" className="mt-1">
                  {session.carClass}
                </Badge>
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Grid Principal */}
      <div className="grid md:grid-cols-2 gap-4">
        {/* Informações da Pista */}
        <Card className="bg-gray-800/50 backdrop-blur-sm">
          <CardContent className="pt-6">
            <div className="space-y-4">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-semibold text-white mb-1">
                    {session.trackName || 'Unknown Track'}
                  </h3>
                  <div className="flex items-center gap-2 text-gray-400">
                    <MapPin size={16} />
                    <span>{session.trackCity}, {session.trackCountry}</span>
                  </div>
                </div>
                {session.trackConfig && (
                  <Badge variant="secondary">
                    {session.trackConfig}
                  </Badge>
                )}
              </div>
              
              <div className="flex items-center gap-2 text-sm text-gray-400">
                <Route size={16} />
                <span>{formatTrackLength(session.trackLength)}</span>
              </div>

              <div className="mt-4 space-y-2">
              <div className="flex items-center gap-2">
  <Timer size={16} className="text-gray-400" />
  <span className="text-sm text-gray-400">
    Sessão: <span className="text-white">{formatSessionName(session.sessionName)}</span>
  </span>
</div>
                <div className="flex items-center gap-2">
                  <CircleDot size={16} className="text-gray-400" />
                  <span className="text-sm text-gray-400">
                    Aderência: <span className="text-white">{session.trackRubberState}</span>
                  </span>
                </div>
              </div>

              <div className="flex flex-wrap gap-2">
                {getTrackConditionIcon(session.trackDynamic)}
                {getSessionStatusBadge(session.isOfficial)}
                {getSetupTypeBadge(session.isFixedSetup)}
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Condições Climáticas */}
        <Card className="bg-gray-800/50 backdrop-blur-sm">
          <CardContent className="pt-6">
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-white mb-4">Condições</h3>
              
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="flex items-center gap-2 mb-1">
                    <Thermometer size={16} className="text-gray-400" />
                    <span className="text-sm text-gray-400">Ar</span>
                  </div>
                  <p className="text-xl font-medium text-white">
                    {formatTemperature(weather.temperature)}
                  </p>
                </div>
                <div>
                  <div className="flex items-center gap-2 mb-1">
                    <ThermometerSun size={16} className="text-gray-400" />
                    <span className="text-sm text-gray-400">Pista</span>
                  </div>
                  <p className="text-xl font-medium text-white">
                    {formatTemperature(weather.trackTemp)}
                  </p>
                </div>
              </div>

              <Separator className="my-4" />

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="flex items-center gap-2 mb-1">
                    <Cloud size={16} className="text-gray-400" />
                    <span className="text-sm text-gray-400">Céu</span>
                  </div>
                  <p className="text-white">
                    {session.trackSkies || 'Clear'}
                  </p>
                </div>
                <div>
                  <div className="flex items-center gap-2 mb-1">
                    <CloudRain size={16} className="text-gray-400" />
                    <span className="text-sm text-gray-400">Precipitação</span>
                  </div>
                  <p className="text-white">
                    {`${session.trackPrecipitation || 0}%`}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="flex items-center gap-2 mb-1">
                    <Droplets size={16} className="text-gray-400" />
                    <span className="text-sm text-gray-400">Umidade</span>
                  </div>
                  <p className="text-white">
                    {formatHumidity(weather.humidity)}
                  </p>
                </div>
                <div>
                <div className="flex items-center gap-2 mb-1">
  <Wind size={16} className="text-gray-400" />
  <span className="text-sm text-gray-400">Vento</span>
</div>
<div className="flex items-center gap-3">
  <p className="text-white">
    {formatWindSpeed(weather.windSpeed)}
  </p>
  <div className="flex items-center gap-1">
    <Compass size={16} className="text-gray-400" />
    <span className="text-white">{weather.windDirection}</span>
  </div>
</div>
                </div>
              </div>

              {/* Névoa */}
              {session.trackFogLevel > 0 && (
                <div className="mt-2">
                  <div className="flex items-center gap-2 mb-1">
                    <CloudFog size={16} className="text-gray-400" />
                    <span className="text-sm text-gray-400">Névoa</span>
                  </div>
                  <p className="text-white">
                    {`${session.trackFogLevel}%`}
                  </p>
                </div>
                
              )}
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
  <div>
    <div className="flex items-center gap-2 mb-1">
      <CloudFog size={16} className="text-gray-400" />
      <span className="text-sm text-gray-400">Névoa</span>
    </div>
    <p className="text-white">
      {`${session.trackFogLevel}%`}
    </p>
  </div>
</div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}// Componente principal do Dashboard
const TelemetryDashboard = () => {
  const [file, setFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [telemetryData, setTelemetryData] = useState(null);
  const [sessionInfo, setSessionInfo] = useState(null);
  


  const handleFileSelect = useCallback(async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (!selectedFile) return;
    
      if (!selectedFile.name.endsWith('.ibt')) {
        setError('Por favor, selecione um arquivo .ibt válido');
        return;
      }
    
      setFile(selectedFile);
      setIsProcessing(true);
      setError(null);
      
      const parser = new createIbtParser();
      parser.setDebug(true);
      const result = await parser.parseFile(selectedFile);
  
      // Log para debug
      
      setSessionInfo({
        ...result.sessionInfo,
        session: {
          sessionName: result.sessionInfo.SessionInfo?.Sessions?.[0]?.SessionName || 
                      result.sessionInfo.SessionInfo?.Sessions?.[0]?.SessionType || 
                      result.sessionInfo.WeekendInfo?.EventType || 
                      'Unknown Session',
          trackRubberState: result.sessionInfo.SessionInfo?.Sessions?.[0]?.SessionTrackRubberState || 'moderately high usage',
          type: result.sessionInfo.WeekendInfo?.EventType || 'Race',
          trackName: result.sessionInfo.WeekendInfo?.TrackDisplayName || 'Unknown Track',
          trackConfig: result.sessionInfo.WeekendInfo?.TrackConfigName || '',
          trackLength: result.sessionInfo.WeekendInfo?.TrackLength || 0,
          carName: result.sessionInfo.DriverInfo?.TelemetryOwner?.CarScreenName || 'Unknown Car',
          carClass: result.sessionInfo.DriverInfo?.TelemetryOwner?.CarClassShortName || '',
          trackCity: result.sessionInfo.WeekendInfo?.TrackCity || 'Unknown City',
          trackCountry: result.sessionInfo.WeekendInfo?.TrackCountry || 'Unknown Country',
          trackSkies: result.sessionInfo.WeekendInfo?.TrackSkies || 'Clear',
          isOfficial: result.sessionInfo.WeekendInfo?.Official?.value === 1,
          trackDynamic: result.sessionInfo.WeekendInfo?.TrackDynamicTrack?.value === 1,
          trackFogLevel: result.sessionInfo.WeekendInfo?.TrackFogLevel?.value || 0,
          trackPrecipitation: result.sessionInfo.WeekendInfo?.TrackPrecipitation?.value || 0,
          isFixedSetup: result.sessionInfo.WeekendInfo?.WeekendOptions?.IsFixedSetup?.value === 1
        },
        driver: {
          driverName: result.sessionInfo.DriverInfo?.TelemetryOwner?.UserName || 'Unknown Driver',
          userId: result.sessionInfo.DriverInfo?.TelemetryOwner?.UserID,
          iRating: parseInt(result.sessionInfo.DriverInfo?.TelemetryOwner?.IRating) || 0,
          licenseLevel: parseInt(result.sessionInfo.DriverInfo?.TelemetryOwner?.LicLevel) || 0,
          licenseSubLevel: parseInt(result.sessionInfo.DriverInfo?.TelemetryOwner?.LicSubLevel) || 0
        },
        weather: {
          temperature: result.sessionInfo.WeekendInfo?.TrackAirTemp?.value || 0,
          trackTemp: result.sessionInfo.WeekendInfo?.TrackSurfaceTemp?.value || 0,
          humidity: result.sessionInfo.WeekendInfo?.TrackRelativeHumidity?.value || 0,
          windSpeed: result.sessionInfo.WeekendInfo?.TrackWindVel?.value || 0,
          windDirection: result.sessionInfo.WeekendInfo?.WeekendOptions?.WindDirection || 'N'
        }
      });
      
      setTelemetryData(result);
    } catch (err) {
      console.error('Erro ao processar arquivo:', err);
      setError(`Erro ao processar arquivo: ${err.message}`);
      setTelemetryData(null);
      setSessionInfo(null);
    } finally {
      setIsProcessing(false);
    }
  }, []);

  const renderContent = () => {
    if (telemetryData && sessionInfo) {
      return (
        <div className="flex flex-col space-y-6">
          {/* Info Headers sem o mapa */}
          <div className="max-w-[1600px] mx-auto w-full">
            <SessionInfo info={sessionInfo} />
          </div>

          {/* TelemetryAnalyzer em full width */}
          <div className="w-full">
            <TelemetryErrorBoundary>
              <TelemetryAnalyzer 
                data={telemetryData} 
                sessionInfo={sessionInfo} 
              />
            </TelemetryErrorBoundary>
          </div>
        </div>
      );
    }

    return (
      <div className="max-w-4xl mx-auto space-y-4">
        <FileUploader 
          isProcessing={isProcessing}
          onFileSelect={handleFileSelect}
        />
        
        {error && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Erro</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {file && !error && isProcessing && (
          <ProcessingStatus file={file} />
        )}
      </div>
    );
  };

  return (
    <div className="min-h-screen">
      {renderContent()}
    </div>
  );
};

export default TelemetryDashboard;
